import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const Description = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text.purple};
`

const Block = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);

  & p,
  & h3 {
    color: ${({ theme }) => theme.color.text.purple};
  }

  & h3 {
    font-weight: ${({ theme }) => theme.font.weight.l};

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size[28]};
    }

    @media (max-width: 991.98px) {
      font-size: ${({ theme }) => theme.font.size.xm};
    }
  }
`

const BlockImage = styled(Image)`
  width: 100%;

  @media (min-width: 992px) {
    height: 100px;
  }

  @media (max-width: 991px) {
    height: 75px;
  }

  @media (max-width: 767px) {
    height: 75px;
  }
`

interface DescriptionWithBlocksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_DescriptionWithBlocks
}

const DescriptionWithBlocks: React.FC<DescriptionWithBlocksProps> = ({
  fields,
}) => (
  <section className="color-background-background py-5">
    <div className="container py-lg-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <Description content={fields?.description} />

          <div className="row mt-5">
            {fields?.blocks?.map((edge) => (
              <div key={edge?.title} className="col-6 mb-4">
                <Block className="px-3 py-4 px-lg-4 py-lg-5 h-100">
                  <h3 className="text-center mb-4">{edge?.title}</h3>
                  <BlockImage
                    className="mb-4"
                    image={edge?.image}
                    alt={edge?.title}
                  />
                  <ParseContent
                    className="text-center"
                    content={edge?.description}
                  />
                </Block>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default DescriptionWithBlocks
